import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, SectionWrapper, Title } from "../../core/commonExports"

const QuotesFromCompanies = ({
  style={},
  mobileStyles={background: "#F6F9FF"},
  desktopStyles={background: "#fff"},
  sectionBg = "#FFF7F1",
  title="Get instant Quotes from India’s biggest insurance companies",
  titleStyle={}
}) => {
  const imgStyles = {
    borderRadius: "10px",
  }

  return (
    <Section background={sectionBg} mobileStyles={mobileStyles} desktopStyles={desktopStyles} style={style}>
      <SectionWrapper>
        <Title fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="32px" fontWeight="700" style={{textAlign: "center", ...titleStyle}} desktopStyles={{marginBottom: "64px"}} mobileStyles={{marginBottom: "40px"}}>
          {title}
        </Title>
        <InsuranceCompanies>
          <GovtOwnedTag>
            <InsurerImageContainer>
              <StaticImage 
                src="../../../assets/images/insurers/Oriental.webp"
                alt="oriental"
                loading="lazy"
                width={160}
                style={imgStyles}
              />
            </InsurerImageContainer>
          </GovtOwnedTag>
          <GovtOwnedTag>
            <InsurerImageContainer>
              <StaticImage 
                src="../../../assets/images/insurers/NewIndia.webp"
                alt="new-india"
                loading="lazy"
                width={160}
                style={imgStyles}
              />
            </InsurerImageContainer>
          </GovtOwnedTag>
          <GovtOwnedTag>
            <InsurerImageContainer>
              <StaticImage 
                src="../../../assets/images/insurers/UnitedIndia.webp"
                alt="united-india"
                loading="lazy"
                width={160}
                style={imgStyles}
              />
            </InsurerImageContainer>
          </GovtOwnedTag>
          <GovtOwnedTag>
            <InsurerImageContainer>
              <StaticImage 
                src="../../../assets/images/insurers/NationalInsurance.webp"
                alt="national-insurance"
                loading="lazy"
                width={160}
                style={imgStyles}
              />
            </InsurerImageContainer>
          </GovtOwnedTag>
          <InsurerImageContainer>
            <StaticImage 
              src="../../../assets/images/insurers/ICICILombard.webp"
              alt="icici-lombard"
              loading="lazy"
              width={160}
              style={imgStyles}
            />
          </InsurerImageContainer>
          
          <InsurerImageContainer hideOnDesktop>
            <StaticImage 
              src="../../../assets/images/insurers/RelianceGeneral.webp"
              alt="reliance-general"
              loading="lazy"
              width={160}
              style={imgStyles}
            />
          </InsurerImageContainer>
          {/* <MoreInsCompanies fontWeight="600" fontSize="26px" mfontSize="16px">& many more </MoreInsCompanies> */}
        </InsuranceCompanies>
      </SectionWrapper>
    </Section>
  )
}

const GovtOwnedTag = styled.div`
  border-radius: 10px;
  position: relative;
  &::before {
    content: "Government owned";
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    top: -24px;
    z-index: 1;
    text-align: center;
    width: fit-content;
    background: #FF934F;
    border-radius: 6px;
    color: #fff;
    padding: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  @media screen and (max-width: 768px){
    width: 100px;
    &::before {
      top: -16px;
      font-size: 9px;
      line-height: 10px;
      padding: 6px;
      width: 98%;
    }
  }
`

const InsurerImageContainer = styled.div`
  display: ${p => p.hideOnDesktop ? "none" : "flex"};
  justify-content: center;
  width: 160px;
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 768px){
    width: 110px;
    display: flex;
  }
`

const InsuranceCompanies = styled.div`
  width: 100%; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  gap: 2rem;
  flex-wrap: wrap;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.15));
  @media (max-width: 1080px) {
    /* gap: 1rem; */
    filter: none;
  }
  @media (max-width: 768px) {
    /* & > * {
      width: 150px;
    } */
    gap: 2.5rem 3.25rem;
    justify-content: center;
    flex-wrap: wrap !important;
  }
`

export default QuotesFromCompanies